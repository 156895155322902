import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Enero"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/enero"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/enero",
        title: "Efemérides - Enero | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – ENERO</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de enero de 1785:&nbsp;Nació en Salta, José de Moldes. En 1803, sus
      padres lo enviaron a España para que estudie en Madrid. Luchó en Europa a
      favor del Rey de España. En 1809, regresó al país y recorrió Córdoba,
      Santiago del Estero, Tucumán, Salta, Cochabamba y otras ciudades
      preparando la revolución de 1810. La junta de gobierno de Buenos Aires, le
      confió el cargo de Teniente Gobernador de Mendoza en julio de 1810.
      Regreso a Salta y se incorporó al Ejército Auxiliar del Alto Perú. En
      Cochabamba fue nombrado intendente. Belgrano lo ascendió al grado de
      coronel. Güemes lo nombró representante de Salta en el Congreso de Tucumán
      junto al doctor Mariano Boedo y del general Gorriti. Murió en Buenos
      Aires, el 18 de abril de 1824.
    </p>
    <p>
      1 de enero de 1824:&nbsp;Asumió como gobernador de Salta, don Antonio
      Alvarez de Arenales, puesto que el doctor José Antonio Gorriti no aceptó
      su reelección, por respeto a las leyes.
    </p>
    <p>
      1 de enero de 1837:&nbsp;Nació en Salta, Manuel Solá Chavarría. Por
      problemas políticos de sus padres, la infancia y gran parte de la juventud
      la pasó en Valparaiso, Chile. En 1870 regresó a Salta, donde se dedicó al
      periodismo y a la política. Fue ministro de hacienda, diputado nacional,
      presidente del Consejo General de Educación. Publicó las siguientes obras:
      estudio sobre el Ferrocarril Trasandino de Salta a Mejillones o
      Antofagasta, compilación del trazado del F.C.N. por el Valle de Lerma
      (1883); Ensayo histórico sobre la provincia de Salta (1895); Bocetos
      biográficos de salteños ilustres (1897); La liga del Norte contra Rosas
      (1898). Murió en Salta, el 31 de julio de 1907.
    </p>
    <p>
      1 de enero de 1883: Se promulgó la Nueva Constitución de la Provincia de
      Salta, reformada por Ley Nº 102, del año 1880.
    </p>
    <p>
      1 de enero de 1912:&nbsp;Se fundó la sociedad de beneficencia, que trabajó
      para el Hospital de Metán. Surgió la iniciativa del señor Justo Toledo y
      luego se destacaron por su labor Mercedes Torrens de Escudero, Angela
      Medrano de Madariaga y Elisa Pagani.
    </p>
    <p>
      1 de enero de 1916:&nbsp;Comenzó a funcionar el primer servicio de
      alumbrado público eléctrico y también el servicio de electricidad a
      particulares de Metán. Estaba alimentado por un motor a vapor que el señor
      Pedro Petersen, de origen danés, tenía en su aserradero, ubicado en la
      calle 9 de Julio y Güemes, al lado de las vías del ferrocarril.
    </p>
    <p>
      1 de enero de 1923: Murió en Buenos Aires, don Domingo Güemes, nieto del
      general. El doctor Domingo Güemes fue el hijo mayor de Luis Güemes Puch y
      de doña Rosaura Castro, habiendo nacido en Salta en 1853. Nieto del
      emancipador y su esposa Carmen Puch. En Buenos Aires se graduó de doctor
      en leyes. El general Dionisio Puch, cuñado del general Güemes, consiguió
      entre 1870 y 1871, le remitieran desde Salta gran parte de la
      documentación del héroe, para componer y publicar una biografía. En 1872
      desistió de este propósito y encomendó a su sobrino nieto Domingo Güemes,
      que recogiera la documentación que estaba en poder del doctor Dalmacio
      Vélez Sársfield. Regresó a Salta donde fue legislador provincial,
      intendente de la capital y ministro de gobierno. En 1883 fue elegido
      diputado nacional. Trabajó incansablemente por la obra Güemes Documentado.
    </p>
    <p>
      1 de enero de 1946:&nbsp;Nació en Salta, Roberto Luis Orce. Contador
      Público Nacional, que se dedica a la docencia universitaria. Ocupó
      distintas funciones en la administración pública. Fue ministro de Gobierno
      de la provincia de Salta.
    </p>
    <p>
      1 de enero de 1951:&nbsp;Nació en Salta, Luis Alberto Magno. Médico que se
      desempeñó como médico asistencial desde 1978 a 1982, Director del Hospital
      de Cachi en 1982 a 1987, Intendente Municipal de Cachi de 1987 a 1993 y
      Diputado Provincial por Cachi desde 1993 a 1997, cargo en el que fue
      reelecto.
    </p>
    <p>
      1 de enero de 1966:&nbsp;Nació en Salta, Cesáreo José Pastrana. Egresó de
      la Escuela Normal de Cafayate y de Procurador de la Universidad Nacional
      de Tucumán. Cursa abogacía. Docente y fundador, a cargo de la presidencia
      de la Asociación de Residentes Cafayateños en Tucumán.
    </p>
    <p>
      1 de enero de 1987:&nbsp;Inició sus actividades de prestaciones médicas,
      en la ciudad de Salta, Medisalta, una empresa nacida en el seno de la
      Asociación de Clínicas y Sanatorios Privados de Salta.
    </p>
    <p>
      1 de enero de 1989:&nbsp;Salió por primera vez al aire FM La Esperanza,
      que se encuentra ubicada en la intersección de las calles Lavalle y Moreno
      de la localidad de Joaquín V. González, en la provincia de Salta. Su
      director y propietario es el señor José Antonio González.
    </p>
    <p>
      2 de enero de 1987:&nbsp;Inició sus actividades de prestaciones médicas,
      en la ciudad de Salta, Medisalta, una empresa nacida en el seno de la
      Asociación de Clínicas y Sanatorios Privados de Salta.
    </p>
    <p>
      3 de enero de 1881:&nbsp;Murió en Salta, Juan Francisco de Castro, quien
      estudió Derecho Canónico y Teología en Chuquisaca, ciudad donde se ordenó
      como sacerdote. Fue párroco de la catedral de Salta. Fundador y primer
      rector del Colegio Nacional de Salta.
    </p>
    <p>
      3 de enero de 1941:&nbsp;Nació en Salta, Daniel Cancio Toro Velázquez.
      Músico que integró el conjunto folklórico Los Nombradores. Se desvinculó
      de la década del 60 para lanzarse como solista, logrando ganar un
      importante lugar entre los artistas argentinos. Es autor y compositor de
      reconocidos temas del cancionero popular. En la actualidad sigue creando
      en fe, esperanza, amor, ternura, el respeto que siente por la vida, en
      nombre de Dios. Algunos temas los firmó con el seudónimo Casimiro Cobos,
      pero el nombre que quedó prendido en la memoria popular es el de Daniel
      Toro. Actual asesor de Cultura de la Provincia.
    </p>
    <p>
      4 de enero de 1884:&nbsp;Se asignó el impuesto a la extracción de la sal
      para la obra de construcción del templo de la Candelaria de la Viña que
      ejecutaba don Macchi, ya que había terminado su trabajo en la Catedral de
      Salta.
    </p>
    <p>
      4 de enero de 1895:&nbsp;Fue consagrada la iglesia de cinco naves en
      Cafayate, tras cinco años de labor. Lo consagró el Obispo de Salta,
      monseñor Pablo Padilla y Bárcena. Con el tiempo se convirtió en la Iglesia
      Catedral de la Prelatura de Cafayate, que fuera creada por el Papa Pablo
      VI en 1969 y entregada a los Agustinos de la “Provincia de España”.
    </p>
    <p>
      4 de enero de 1907:&nbsp;Se organizó una gran fiesta popular en el Cerro
      San Bernardo. Se hicieron peregrinaciones que partieron este día, domingo,
      a la mañana, para visitar el Monumento al Cristo Redentor. Obreros
      iluminaron dicho monumento en la noche del sábado.
    </p>
    <p>
      4 de enero de 1953: Nació en General Güemes, provincia de Salta, Emilia
      Virginia Acosta. Es licenciada en Filosofía Española. Docente a nivel
      universitario y secundario. Como poeta publicó algunos libros. Es
      directora y Editora de la revista – libro “Encuentro Escrito”.
    </p>
    <p>
      4 de enero de 1992:&nbsp;Falleció en Salta, la poetisa Clara Saravia
      Linares de Arias. Dedicó su vida a la docencia y a la literatura. A los
      veinte años publicó una novela que tituló Lirios de otoño. Un año más
      tarde un libro de lectura para grados superiores: Por los prados del alma.
      Como poetisa logró premios y reconocimientos. Publicó en poesía: ¡Más
      amor! (1977). Es autora de varias obras de teatro. Dejó varios libros
      inéditos.
    </p>
    <p>
      5 de enero de 1735:&nbsp;Estando de gobernador de Salta, Don Juan de
      Armasa y Arregui, los indígenas llevaron a cabo una invasión a la ciudad.
      Sus habitantes fueron testigos del mayor daño realizado por los
      aborígenes. Cautivaron a muchos, mataron a 300 personas y se llevaron un
      importante botín.
    </p>
    <p>
      5 de enero de 1914:&nbsp;Cuando la salud del Obispo Matías Linares
      declinaba, fue informado de un singular proyecto de obras a construirse en
      el cerro San Bernardo y adyacentes. El Obispo respondió complacido.
    </p>
    <p>
      5 de enero de 1935:&nbsp;Nació en Salta, Jacobo Regen. Se dedicó al
      periodismo y a las letras. Publicó como poeta: Canción del ángel
      (1964-Tucumán); Canción del ángel y otros poemas (1971-Salta); Umbroso
      mundo (1971); El vendedor de tierra (1981- Primer premio de poesía de la
      Dirección de Cultura de Salta en 1978). Además publicó en plaqueta: seis
      poemas (1962). Fue distinguido con el premio Ricardo Jaimes Freyre a la
      producción poética de Tucumán en el bieno 1964-1965. Logró la segunda
      mención del concurso “Los Cuentos inéditos” premiados en Salta, en el año
      1975, junto con Néstor Saavedra, Oscar Pérez, Andrés Rodolfo Villalba y
      Rodolfo I. Bravo.
    </p>
    <p>
      5 de enero de 1988:&nbsp;El Intendente del balneario bonaerense de Santa
      Teresita, en el partido de la Costa, don Juan de Jesús, descubrió un busto
      de Martín Miguel de Güemes, en la calle Salta del mencionado municipio.
      Asistieron al acto el gobernador de Salta, C.P.N. Hernán Hipólito Cornejo
      y el Diputado Nacional por Salta, don Roberto Romero. También estuvo
      presente el Sr. Lázaro Freidenberg, fundador del balneario Santa Teresita
      y propulsor de la ciudad termal de Rosario de la Frontera, en la provincia
      de Salta.
    </p>
    <p>
      6 de enero de 1817:&nbsp;“Los Infernales”, del ejército del general Martín
      Miguel de Güemes, a cargo del comandante Juan Antonio Rojas, derrotaron a
      los realistas en avanzada, en San Pedrito, provincia de Jujuy. Mientras
      tanto el brigadier Pedro Antonio de Olañeta al frente del ejército
      realista, derrotaba a las tropas patriotas y tomaba la plaza de la ciudad
      de Jujuy.
    </p>
    <p>
      6 de enero de 1831: El mayor Pedro Ignacio Luna, federal, derrotó al
      coronel Agustín Arias, unitario, quien murió en la acción, ocurrida en
      Vinal, provincia de Santiago del Estero. Arias había actuado como auxiliar
      en el Ejército del Norte y luego estuvo bajo las órdenes del general
      Martín Miguel de Güemes.
    </p>
    <p>
      6 de enero de 1903:&nbsp;Se inauguró, en Metán, la sucursal del Banco
      Provincial de Salta, en calle 9 de Julio N° 250. Su primer gerente fue don
      Gabino Rodas Torino.
    </p>
    <p>
      6 de enero de 1903:&nbsp;Se inauguró el primer tramo del ramalferroviario
      del norte, llegando hasta Ledesma, en la provincia de Jujuy años más tarde
      llegaría hasta Orán, en la provincia de Salta y luego hasta Oriente
      Boliviano.
    </p>
    <p>
      6 de enero de 1958:&nbsp;Se inauguró en Metán, provincia de Salta, la
      sucursal del Banco Provincial de Salta, en la calle 9 de Julio Nº 250. Su
      primer gerente fue don Gabino Rodas Torino.
    </p>
    <p>
      6 de enero de 1969:&nbsp;Se inauguró, en Metán, la Guardería Infantil Juan
      XXIII, una de las primeras de su tipo, en la provincia. Su nombre lo
      sugirió, Monseñor Carlos Mariano Pérez. Su primera presidente fue doña
      Carmen O. de Zurro.
    </p>
    <p>
      7 de enero de 1784: La Intendencia de Salta del Tucumán, quedó
      definitivamente instalada. Al frente de la misma fue designado don Andrés
      Mestre, cuyo nombramiento fue enviado por la Corona de España el 22 de
      agosto de 1783; convirtiéndose de esta manera en el último gobernador de
      la antigua provincia del Tucumán y en el primero de la intendencia de
      Salta del Tucumán. A su nuevo nombramiento debía sumarse el de brigadier,
      alcanzado en 1783 en la carrera de las armas.
    </p>
    <p>
      7 de enero de 1817:&nbsp;El coronel Manuel Eduardo Arias, del ejército de
      los gauchos salteños mandados por el general Martín Miguel de Güemes,
      derrotó a los realistas, al mando del coronel Guillermo Marquiegui, en San
      Andrés, provincia de Salta. Marquiegui era salteño de nacimiento, pero
      prestaba servicios para la Corona de España.
    </p>
    <p>
      7 de enero de 1854:&nbsp;Falleció en Salta, el prebístero Benjamín J.
      Lavaysse, quien había sido electo diputado al Congreso Constituyente, por
      Santiago del Estero el 9 de agosto de 1852, junto con el doctor José
      Benjamín Gorostiaga.
    </p>
    <p>
      7 de enero de 1888:&nbsp;Nació en Cafayate, provincia de Salta, David
      Michel Torino. Fue diputado provincial por Cafayate en dos períodos
      representado en la Unión Cívica Radical. Dirigió el diario El
      Intransigente, como director y propietario, que en sus inicios se denominó
      El Cívico Intransigente, fundado el 17 de abril de 1920. Como periodista
      logró importantes premios y reconocimientos. Fue presidente de la Bolsa de
      Comercio de Salta y presidente del Club Gimnasia y Tiro por 28 años.
      Publicó en 1953, el libro: Desde mi celda. Falleció en Salta, el 17 de
      junio de 1960.
    </p>
    <p>
      7 de enero de 1910:&nbsp;Por Ley N° 848, se autorizó al Poder Ejecutivo de
      la Provincia a invertir la suma de $ 6.000 m/m, en las obras necesarias
      para dotar de agua corriente a Metán.
    </p>
    <p>
      7 de enero de 1919:&nbsp;Asumió el doctor Joaquín Castellanos la
      gobernación de Salta, siendo el primer gobernador radical que tuvo esta
      provincia.
    </p>
    <p>
      7 de enero de 1931: Fue librado al servicio público, el ferrocarril que
      unía el tramo Embarcación con Formosa.
    </p>
    <p>
      7 de enero de 1956: Nació en Salta, Rafael Antonio Tissera. En 1972 se
      recibió de médico en la Universidad de Buenos Aires. Se especializó en
      oftalmología. Fue becado por la Asociación Panamericana de oftalmología en
      Puerto Rico.
    </p>
    <p>
      8 de enero de 1811:&nbsp;En Potosí, Güemes fue separado del Ejército
      Auxiliar y su División distribuida entre las otras divisiones.
    </p>
    <p>
      8 de enero de 1922:&nbsp;Se realizaron comicios en la provincia,
      resultando electo gobernador de Salta, el doctor Adolfo Güemes.
    </p>
    <p>
      9 de enero de 1900:&nbsp;Nació en Salta, Alberto Francisco Caro. Fue
      diputado por Metán, Interventor de la Municipalidad de Metán, Senador
      provincial y ministro de Acción Social y Salud Pública. Este médico murió
      en Buenos Aires, el 29 de abril de 1960.
    </p>
    <p>
      9 de enero de 1937:&nbsp;Fue declarado Monumento Histórico por Ley N°
      12.346, artículo 70, el Cabildo de Salta. Se decretó la construcción el
      mismo día de la fundación de la ciudad, el 16 de abril de 1582, por don
      Hernando de Lerma para asiento de las autoridades de Salta del Tucumán. En
      1888 se remató el edificio y vendido en lotes en 1889, pasó a propiedad
      particular y alquilado para casas de comercio. En 1940 fue sancionado un
      proyecto que presentó el doctor Carlos Serrey, para que el edificio fuera
      adquirido por el Estado. Luego de engorrosos pleitos, se logró desalojar a
      los ocupantes y se emprendió la restauración, que quedó terminada a
      principios de 1945. Estas mejoras fueron realizadas por la dirección
      nacional de arquitectura del Ministerio de Obras Públicas y estuvieron a
      cargo del arquitecto Mario J. Buschiazzo. El 16 de abril de 1945 se
      efectuó la ceremonia inaugural. Hoy es sede del MUSEO/ Nacional, está
      ubicado en la calle Caseros, entre Alberdi y Buenos Aires, frente a la
      plaza 9 de julio.
    </p>
    <p>
      9 de enero de 1938:&nbsp;Nació en Salta, Teresa Alicia Leonardi Herrán.
      Profesora de Filosofía. Poeta con varias obras publicadas. Militante de
      Derechos Humanos.
    </p>
    <p>
      9 de enero de 1952:&nbsp;Nació en Chile, Alfredo García Castelblanco.
      Estudió en la Academia Diplomática Andrés Bello de Chile. Fue Cónsul
      General de Chile en Salta. Antes estuvo en la India, en Singapur, en
      Austria.
    </p>
    <p>
      10 de enero de 1950:&nbsp;Asumió como gobernador de Salta elegido por el
      pueblo el doctor Oscar H. Costas y Carlos Xamena, como vicegobernador.
    </p>
    <p>
      10 de enero de 1957:&nbsp;Nació en Tartagal, provincia de Salta, Héctor
      René Schmunk. Músico que se inició desde los 12 años. Integró grupos
      folklóricos. En 1986 se inicia como humorista con el seudónimo artístico
      de Abel Peña. Es autor y compositor recorrió Europa con sus canciones.
    </p>
    <p>
      11 de enero de 1885:&nbsp;Se eligió Presidente del Concejo Municipal de
      Metán a don Enrique Lona y se incorporó como concejal don Emilio Gauffín.
    </p>
    <p>
      11 de enero de 1887:&nbsp;Nació en la Montaña, San Lorenzo, provincia de
      Salta, Juan Carlos Dávalos. Hijo del jurisconsulto Arturo León Dávalos y
      de doña Isabel Patrón Costas, nieta de Francisco Güemes, hermano del
      héroe. Este escritor y poeta era bisnieto de don Nicolás Severo Isasmendi,
      último gobernador realista y propietario de la hacienda-encomienda de
      Molinos. Su obra es basta y su libro más difundido El Viento Blanco.
      Falleció el 6 de noviembre de 1959.
    </p>
    <p>
      11 de enero de 1910:&nbsp;Nació en Puebla del Río, Sevilla, España, Manuel
      García Soriano. Desde niño se radicó en Tucumán. Fue profesor de la
      Universidad Nacional de Tucumán. Publicó: Pedro Abandonao (poesía – 1938);
      El libro de Estela (poesía – 1939); Humo de chimenea (1940); El
      conquistador español del siglo XVI (1951); Urquiza y la conspiración
      unitaria en el interior, 1852 – 1854 (1954); Homenaje a Puebla del Río
      (folleto – 1966); Tucumán, de Caseros a Los Laureles, 1852 – 1854 (1969).
      Murió en el mismo lugar del nacimiento, Puebla del Río,Sevilla, España, el
      25 de mayo de 1970.
    </p>
    <p>
      11 de enero de 1935:&nbsp;Nació en Salta, José Esper. Periodista, poeta,
      folklorólogo. Actuó en el comercio, en la cultura. Fue premiado como poeta
      en Tucumán, en 1964, en los Juegos Florales Norte. Actuó en la política
      ocupando varias funciones. Participó en cursos nacionales e
      internacionales de folklorología, Perú, Ecuador, Brasil, México y en un
      Encuentro Mundial organizado por la Unesco, en Suiza en 1985.
    </p>
    <p>
      12 de enero de 1869: El coronel Pedro Corvalán, del ejército nacional,
      derrotó al coronel Felipe Varela, revolucionario, en Pasto Grande,
      provincia de Salta.
    </p>
    <p>
      12 de enero de 1916:&nbsp;Se fundó el Centro Juventud Antoniana. El acta
      de fundación llegó desde Roma y estaba redactada en latín clásico. El
      Padre Enrique Giaggini que era el Guardián del convento San Francisco, fue
      uno de los gestores, quien pidió autorización para la fundación al obispo
      Gregorio Romero. La primera Comisión estuvo presidida por el ingeniero
      Alfonso Peralta. Las primera reunión se realizó al otro día, 13 de enero
      de 1916.
    </p>
    <p>
      12 de enero de 1922:&nbsp;Falleció en la Capital Federal, Moisés Numa
      Castellanos. Nació en Salta en 1869. Se readicó en Buenos Aires en 1900,
      donde integró grupos literarios con Rafael Obligado, entre otros. No
      publicó libros, su poesía se empezó a publicar en 1890.
    </p>
    <p>
      12 de enero de 1954:&nbsp;Nació en Salta, Francisco Salvador Trovato.
      Contador Público Nacional, que realizó una brillante carrera
      administrativa en el Banco de Préstamos y Asistencia Social, entidad donde
      en la actualidad, es su Gerente General.
    </p>
    <p>
      12 de enero de 1955:&nbsp;Nació en Catamarca, Elsa Aída Carabús. Se
      recibió de abogada en 1975, en la Universidad Nacional de Córdoba. Se
      desempeñó como docente en las cátedras Derecho Civil y en filosofía del
      Derecho. Fue declarada Ciudadana Benemérita de la Provincia de Catamarca y
      la Bolsa de Comercio de Córdoba la incluyó entre los Diez Jóvenes
      Sobresalientes del año 1982. A pesar de ser no vidente es pintora. Ha
      publicado libros de poesía, entre otros: “Una a la inmensa”, “Rumores de
      la vida”. Está radicada en Salta.
    </p>
    <p>
      13 de enero de 1739:&nbsp;Fueron subastadas en Salta, una biblioteca que
      poseía el gobernador de Tucumán, don Juan de Armasa. Se pagó 153 pesos y 6
      reales. Entre las obras se encontraban Historias del Perú, de México, de
      Solís, una Pontifical, Las Leyes de Indias y obras de clásicos españoles y
      biografías de santos.
    </p>
    <p>
      13 de enero de 1809:&nbsp;Mediante Real Orden, se concebían ascensos a los
      defensores de la capital del Virreinato. Martín Miguel Güemes, ya se
      encontraba en Salta, era ascendido a subteniente.
    </p>
    <p>
      13 de enero de 1817:&nbsp;El comandante Bartolomé de la Corte, del
      ejército patriota mandado por el general Martín Miguel de Güemes, derrotó
      a una partida realista, en Alisos, provincia de Jujuy.
    </p>
    <p>
      13 de enero de 1992:&nbsp;Falleció el cantor popular Américo Torres. Había
      nacido en Tartagal. Integró el conjunto Los Gauchos de Güemes.
    </p>
    <p>
      13 de enero de 1995:&nbsp;Se fundó la institución “La Senda Gloriosa”,
      siendo su fundador Marcelo José Farfán. Su primera presidente fue Rosa A.
      Alancay. Los fines fundamentales son difundir la gesta güemesiana y
      vivenciar la historia en los lugares mismos donde ocurrieron los hechos.
    </p>
    <p>
      14 de enero de 1811:&nbsp;Por decreto, Cornelio Saavedra, lo ascendió a
      Martín Miguel de Güemes al cargo de teniente coronel.
    </p>
    <p>
      14 de enero de 1940:&nbsp;Nació en Coquimbo, Chile, José Dellmiro Barraza.
      Desde su niñez se radicó en Salta. Es considerado un artista salteño, por
      todo lo que aportó a la cultura de nuestra provincia. Se destaca como
      pintor, grabador, escenógrafo y experto en luminotecnia. Fue alumno de
      Carlos García Bes y de Luis Preti, en la Escuela de Bellas Artes Tomás
      Cabrera y en otros establecimientos educativos. Participó en numerosas
      muestras colectivas, salones nacionales, provinciales, municipales,
      regionales, en distintas ciudades del país y en países latinoamericanos.
      Obtuvo en su carrera importantes premios en artes plásticas, pintura,
      grabado, dibujo, cerámica, entre los que se destaca el Gran Premio de
      Honor de Dibujo- Salón Municipal. Sus obras se encuentran en manos de
      coleccionistas de todo el mundo y como referencia de algunos lugares
      destacados: en la Pinacoteca de la Universidad Nacional de Salta, en el
      MUSEO/ Provincial de Bellas Artes de Posadas, en la Universidad Nacional
      de Antofagasta, Chile; en la Pinacoteca del Banco del Noroeste de Salta;
      en la colección Vinograf de Israel.
    </p>
    <p>
      14 de enero de 1954:&nbsp;Nació en San Juan, Augusto Saúl Sasso. La
      primaria y la secundaria la realizó en su provincia natal. En 1980 se
      recibió de médico en la Universidad Nacional de Córdoba, donde también fue
      docente universitario. En 1987 se radicó en Salta, donde prestó servicio
      profesionales en el Hospital San Bernardo, Hospital del Milagro y en
      prestaciones privadas.
    </p>
    <p>
      14 de enero de 1955:&nbsp;Nació en Metán, provincia de Salta, Mauro
      Segundo Villafañe. En su ciudad natal, trabaja como comunicador social, en
      radio y televisión, defendiendo la cultura indoamericana y el folklore.
    </p>
    <p>
      15 de enero de 1920:&nbsp;Por decreto de ese día N° 651, se organizó el
      funcionamiento del Archivo Histórico de Salta.
    </p>
    <p>
      15 de enero de 1921:&nbsp;Nació en Tucumán, el doctor Miguel Ragone. Fue
      el primer director del Hospital Neurosiquiátrico “Dr. Christofredo Jakob”.
      Fue gobernador de la provincia de Salta desde enero de 1973 hasta
      noviembre de 1974. Fue víctima de un atentado que nunca más lo reintegró a
      la comunidad.
    </p>
    <p>
      15 de enero de 1932:&nbsp;Nació en Entre Ríos, Rubén Zusman. Fue destacado
      ajedrecista. Comerciante. Preside la Liga Salteña de Fútbol desde hace 9
      períodos.Fue además, miembro del Comité Ejecutivo de la Asociación de
      Fútbol Argentino (AFA).
    </p>
    <p>
      15 de enero de 1957:&nbsp;Nació en Salta, Gerardo Augusto Montaldi
      Mogrovejo. Conductor de programas radiales desde hace seis años en forma
      consecutiva. Fundador del Modín nacional y primer y único concejal del
      interior del país. Terminó su mandato.
    </p>
    <p>
      15 de enero de 1985:&nbsp;Se creó la fundación de Canal 11, por inquietud
      de la Compañía de Radio y Televisión S.A. (CORTESA), que dirige canal 11
      de Salta. La primera comisión directiva estuvo presidida por Elsa Costas
      de Alsina; vicepresidente Mario E. Lacroix; secretaría, Emilce C. de
      González Diez; prosecretaria, María Teresa Sanmillán; tesorera, Carmen R.
      Aguilar de López Cabada; protesorera, Virginia Blaquier de Patrón Costas.
      Esta Fundación realizó muchas actividades de bien público y apoyó
      grandemente la cultura, editando, por ejemplo, obras de poetas y
      escritores de Salta. La presidente en la actualidad es Virginia Blaquier
      de Patrón Costas; vicepresidente, Ana Victoria Cornejo de Patrón Costas;
      secretaria, Maria Teresa Sanmillán; Tesorera, Carmen R. Aguilar de López
      Cabada; vocal titular primera, Silvia Fleming de Cornejo.
    </p>
    <p>
      16 de enero de 1862:&nbsp;Cambio de gobierno en Salta. José María Tood
      dejó el cargo de gobernador y asumió Moisés Oliva.
    </p>
    <p>
      16 de enero de 1946:&nbsp;Nació en Salta, Jorge Raúl Cornejo Albrecht.
      Pintor y poeta. Como pintor expuso en Salta, en el interior de la
      provincia, en otras provincias y países. Como poeta publicó: Eres la
      Montaña, Hermana vida, A los Libres Vientos, El Cantar de mi Silencio,
      Isla Negra y Arenero del Tiempo.
    </p>
    <p>
      17 de enero de 1817:&nbsp;Gauchos patriotas, del ejército del general
      Martín Miguel de Güemes, derrotaron a los realistas, en Río Negro,
      provincia de Salta.
    </p>
    <p>
      17 de enero de 1939:&nbsp;Nació en Salta el poeta Benjamín Toro, autor
      entre otros títulos de “Excedido cielo” (poemas -1972), “El Otoño y sus
      rostros”, libro con el cual logró el primer premio del XII Concurso de
      poesía para autores éditos y que fuera organizado por la Dirección de
      Cultura de la Provincia de Salta en el año 1983. Posee, además, otros
      importantes premios.
    </p>
    <p>
      17 de enero de 1949:&nbsp;Nació en Salta, Carlos Van Cauwlaert. Fue
      integrante del conjunto folklórico Los cuatro Hermanos Salteños. Como
      Licenciado en Administración de Empresas, se desempeñó en el Instituto
      Provincial de Seguros, en la Cámara de Comercio e Industria, en O.S.D.E.,
      en Diario Eco del Norte, en la Confederación Empresaria Salteña. Fue
      Preidente de Emsatur. En la actualidad se desempeña en el Ministerio de
      Trabajo y seguridad de La Nación, con asiento en Salta.
    </p>
    <p>
      18 de enero de 1778:&nbsp;Gabriel de Güemes y Montero tomó posesión de su
      empleo en Salta, trasladándose a Jujuy, los primeros días del mes
      siguiente, donde permaneció hasta enero de 1784, en que se mudó a Salta
      con las cajas de Jujuy por haberse creado la Tesoreria Principal en al
      capital de la provincia. Sirvió a su empleo con gran desinterés y celo
      hasta su muerte, en la laboriosa tarea que demandaba la Caja Matriz y las
      seis subalternas que estaban a su cargo.
    </p>
    <p>
      18 de enero de 1889:&nbsp;Por ley se colocó a los cementerios bajo la
      jurisdicción municipal, en la provincia de Salta.
    </p>
    <p>
      18 de enero de 1933:&nbsp;Nació en España, Vicente Oller Pardo. Fue
      empleado del Banco Provincial de Salta, donde llegó a ser gerente general
      y presidente. Fue presidente del Banco de Préstamo y Asistencia Social. En
      la actualidad es integrante del Centro Empresario Tartagal y director de
      LW2, Radio Emisora Tartagal.
    </p>
    <p>
      18 de enero de 1972: Llegó la autorización del Poder Ejecutivo Nacional,
      para que la Universidad Católica de Salta, pueda crear la Facultad de
      Derecho y Ciencias Sociales.
    </p>
    <p>
      19 de enero de 1817:&nbsp;Gauchos del ejército patriota del General Martín
      Miguel de Güemes, derrotaron a los realistas, en Río de las Piedras,
      provincia de Salta.
    </p>
    <p>
      19 de enero de 1892:&nbsp;Abrió sus puertas la Sucursal del Banco de la
      Nación Argentina, en la ciudad de Salta, siendo su primer gerente el señor
      Delfín Huergo.
    </p>
    <p>
      19 de enero de 1965:&nbsp;Nació en Salta, Noemí Cristina Laspiur de
      Teruel, denominado Folclaro. Es autora de letras populares, que fueron
      grabadas por el conjunto folklórico salteño Los Nocheros.
    </p>
    <p>
      19 de enero de 1978:&nbsp;Se inauguraron en el Club Atlético Pellegrini,
      la cancha de básquet y el local donde funcionaba la Biblioteca Popular
      Juan Carlos Dávalos.
    </p>
    <p>
      20 de enero de 1777:&nbsp;El Rey Carlos III de España, nombró tesorero de
      las Reales Cajas de Jujuy a Gabriel de Güemes y Montero. En noviembre de
      ese año arribó a Buenos Aires procedente de España y en enero de 1778 ya
      estaba en el territorio norte. Tomó funciones en Jujuy y luego se radicó
      en Salta por el traslado de las Cajas. Fue el padre del general Martín
      Miguel de Güemes.
    </p>
    <p>
      20 de enero de 1817:&nbsp;Manuel Rojas fue batido por Olañeta y
      Marquiegui, en el Río de las Piedras, provincia de Salta.
    </p>
    <p>
      20 de enero de 1834:&nbsp;Nació en Salta, Juan Nepomuceno Solá. Ingresó a
      la carrera de las armas muy joven. Actuó en Bolivia y Chile. Luego de
      Caseros regreso al país, incorporándose a las fuerzas nacionales. Estuvo
      en Cepeda y en la guerra contra Paraguay. En 1867 participó en la defensa
      de Salta, el 10 de octubre, contra Felipe Varela. Fue gobernador de Salta
      desde el 30 de julio de 1879, entregando el poder a su sucesor Moisés
      Oliva. Fue comandante de Frontera con la misión de explorar los ríos
      Bermejo y Pilcomayo. Fue elegido por segunda vez gobernador, cuyo mandato
      fue desde el 9 de julio de 1883 hasta el 9 de julio de 1886. Falleció en
      Salta, el 14 de diciembre de 1899.
    </p>
    <p>
      20 de enero de 1882:&nbsp;Nació Manuel Ramón Alvarado en Salta, quien fue
      ministro de gobierno de la provincia, diputado Nacional y ministro de
      Obras Públicas de la Nación. Luchó por la realización del ferrocarril
      trasandino a Chile. Murió en 1953.
    </p>
    <p>
      20 de enero de 1976: Falleció en Salta, el sacerdote Rafael Zuviría. Había
      llegado al Colegio Belgrano en la década del 30. Durante más de treinta
      años se desempeñó como profesor en dicho colegio y en los últimos tiempos
      como secretario. Fue capellán del Colegio del Huerto. Pertenecía a la
      orden lateranense.
    </p>
    <p>
      21 de enero de 1814: El comandante Manuel Dorrego, patriota argentino,
      derrotó a las tropas realistas mandadas por el coronel Saturnino Castro,
      en Quinta Grande, provincia de Salta.
    </p>
    <p>
      21 de enero de 1818:&nbsp;El Cabildo de Salta pedía ayuda a los Cabildo de
      Buenos Aires, Córdoba, Tucumán, Santiago del Estero, Santa Fe y Catamarca,
      oportunidad en la que les decía: “faltan munisiones, no hay numerarios, no
      hay caballos, no hay ganado vacuno, no hay vestuario, ni otro artículo de
      primera necesidad”.
    </p>
    <p>
      21 de enero de 1921:&nbsp;Nació en Salta el poeta Jaime Dávalos, quien
      aportó sus poemas al cancionero argentino, dándole una estatura
      continental. Publicó en libros: “Rastro seco”, “El nombrador”, “Coplas y
      canciones”, “Toro viene el río”, “Poemas y canciones”, “El Poncho”,
      “Solalto” y otros. Grabo con importantes artistas argentinos su poesia y
      actuaba con ellos en muchos escenarios .
    </p>
    <p>
      21 de enero de 1983:&nbsp;Se fundó el Club de Madres de Campo Quijano, lo
      realizó la Señora Magdalena E. de Tello y una comisión de madres.
    </p>
    <p>
      22 de enero de 1782:&nbsp;Nació en San Carlos, en Salta del Tucumán, José
      Mariano Fernández de Córdoba. Estudió en Lima, Perú, en la Universidad de
      San Marcos, donde se graduó como abogado y luego se doctoró en Derecho y
      Derecho Canónico. Siguió estudiando y en 1806 se doctoró en Teología y ese
      mismo año se ordenó de sacerdote. Radicado en Perú donde ejerció el
      apostolado. En 1812 fue designado canónico de la Catedral de Lima. En 1814
      viajó a España para internarse en el Convento de Atocha, de la orden de
      los Predicadores. Se dedicó al estudio y a escribir. De allí su libro
      Teología Moral. Por amistad con el mariscal Sucre, resolvió radicarse en
      Bolivia. En 1848 fue designado Obispo de la Paz. Tuvo que luchar contra la
      oposición y la arbitrariedad del dictador de Bolivia, José María Linares,
      motivo por el cual renunció a la sede Episcopal. Falleció en Sucre, a los
      89 años de edad, el 10 de julio de 1871.
    </p>
    <p>
      22 de enero de 1814:&nbsp;Nació en Salta, Vicente de Anzoátegui y Pacheco,
      quien se doctoró en Derecho en 1835, en la Universidad de Cochabamba. Fue
      diputado constituyente en 1855 y Convencional en 1875. Falleció el 17 de
      octubre de 1881.
    </p>
    <p>
      22 de enero de 1931:&nbsp;Nació en Salta, Bernardo Ramón Biella. En 1957
      se recibió de Ingeniero Industrial, en la Universidad Nacional de Buenos
      Aires. Fue Secretario General de la Gobernación de Salta, cuanbdo era
      gobernador su padre, Bernardino Biella. Fue miembro del directorio
      Horizonte SACIFI; Miembro de la sociedad del diario del Norte e
      Intransigente. Empresario en el rubro inmoviliario y fundador de la Cámara
      Inmobiliaría Salteña. Socio de la Sociedad Argentina de Escritores, Filial
      Salta y autor de dos libros: “La espiral del pensamiento” y “La
      computadora cósmica”.
    </p>
    <p>
      22 de enero de 1947:&nbsp;Nació en Salta, Eduardo Leiva. Cantor popular
      que se presenta con el nombre artístico de Ramiro Luna. 30 años de
      actividad como cantor solista y además, integrante del dúo Los
      Salamanqueros, junto a Oscar Rodríguez, El Mochilero.
    </p>
    <p>
      23 de enero de 1817:&nbsp;Gauchos salteños, del ejército del coronel
      Martín Miguel de Güemes, al mando del teniente Escobedo, derrotaron a los
      realistas, en capilla de Perico, provincia de Jujuy.
    </p>
    <p>
      <strong>
        23 de enero de 1818: es recapturado y encarcelado nuevamente por los
        realistas en el Regimiento de Lima el Marquués de Yavi, Juan José
        Fernandez Campero. Fue procesado nuevamente y declarado culpable –
        puesto en prisión hasta su muerte el día 28 de Octubre de 1820
      </strong>
    </p>
    <p>
      23 de enero de 1821:&nbsp;El Cabildo designó al doctor José Ignacio
      Gorriti para que presidiera la Gobernación-Independencia, durante la
      ausencia del gobernador titular, el general Güemes, quien partió
      comandando el Ejército de Observación.
    </p>
    <p>
      23 de enero de 1873:&nbsp;Nació en Andalgalá, Catamarca, Pedro M. Oviedo.
      Cursó estudios eclesiásticos en el Seminario de Salta, ordenándose
      sacerdote el 21 de diciembre de 1898. En Catamarca desempeñó cátedras en
      el Seminario Conciliar. Fue párroco, legislador provincial.Capellán de la
      Armada Nacional y Vicario General de la Diócesis de Cataamarca. Militó en
      el periodismo y fundó periódicos y revistas. Fue miembro fundador de la
      Junta de Estudios Históricos de Catamarca. Incursionó también en las
      letras. Firmaba con el seudónimo Ricardo Risch. Publicó: “El Zonda”.
      Traadiciones y Leyendas catamarqueñas (1930); Rosa Constancia de Castro
      (1927 – novela); Recuerdos viejos (1901).
    </p>
    <p>
      23 de enero de 1884:&nbsp;Nació en Rosario de Lerma, Salta, Carlos M.
      Cortés. Realizó sus estudios en el Seminario Conciliar de Salta, al que
      ingresó en 1889. Se ordenó de sacerdote el 29 de noviembre de 1908.
      Monseñor Julio Campero y Aráoz, en 1923, lo designó secretario canciller
      del Obispado y lo nombró primer diácono de la Catedral. En 1928 era
      designado Deán de la Catedral. Fue miembro y juez del tribunal
      eclesiástico. En 1933 asumía como provisor y vicario general. Varias veces
      fue gobernador eclesiástico del Arzobispado. En 1936 un Documento
      Pontificio del Papa Pío XI le daba el título de prelado doméstico de su
      Santidad, que incluía tratamiento de monseñor. Falleció el 8 de enero de
      1971.
    </p>
    <p>
      23 de enero de 1919:&nbsp;Nació en Animaná, Salta Rodolfo I. Bravo. Luego
      se radicó en Cafayate, donde fue el director del Museo de Arqueología
      Calchaquí, de su propiedad. Este escritor, investigador y conferecista se
      casó con Holga Hercilia Mazzoni, también literata. Publicó La urna
      funeraria Calchaquí (estudio arqueológico). También publicó: El Tapado del
      El Zorrito (cuento premiado por la Dirección General de Cultura de Salta
      en 1970) y El Mayuma, cuento también premiado por la Dirección de Cultura
      de Salta. Aportó grandemente al conocimiento del Valle Calchaquí.
    </p>
    <p>
      23 de enero de 1961: A los 74 años murió en Salta, Corina Lona. Una calle
      de Salta lleva su nombre. La obra realizada por Corina Lona dió magníficos
      frutos entre sus hijos espirituales.&nbsp;
      <br />
      En su momento la escuela contó con un conjunto folklórico compuesto por no
      videntes que actuaba por Radio General Güemes. Fue la fundadora del Hogar
      de Ciegos que con el tiempo fue bautizado con su nombre, en homenaje a su
      memoria y como reconocimiento por lo realizado en bien de los no videntes.
    </p>
    <p>
      24 de enero de 1958:&nbsp;Nació en Salta el poeta Jesús Ramón Vera, voz
      que testimonia su tiempo, dirige Editorial Tunparenda y es autor de varios
      libros de poemas.
    </p>
    <p>
      24 de enero de 1963:&nbsp;Nació en Capital Federal, Alicia Estela Poderti.
      Profesora y licenciada en letras, investigadora del Conicet, cursa el
      Doctorado en Historia. Docente en la Universidad Nacional de Salta de
      Letras e Historia. Escritora, poeta, narradora, ensayista, que publicó
      varios libros y fue galardonada en distintos certámentes. Publicó en
      libro: Vuelo toronto – Amsterdam (cuentos); San Ramón de la Nueva Orán:
      una ciudad, muchas historias y otros.
    </p>
    <p>
      24 de enero de 1995:&nbsp;Falleció el doctor Oscar V. Oñativia. Dedicó su
      vida a la educación. Creador del Método Integral para la enseñanza de la
      lectura y escritura, con serios fundamentos científicos.
    </p>
    <p>
      25 de enero de 1852:&nbsp;Asumió el gobierno de Salta, don Juan Manuel
      Aguirre.
    </p>
    <p>
      25 de enero de 1944:&nbsp;Nació en Salta, Carlos Visentini. Profesor de
      Historia en la Universidad Nacional de Salta, Director del Colegio J.M.
      Gorriti y Director de la Biblioteca San Francisco.
    </p>
    <p>
      25 de enero de 1957: Fue declarada Monumento Histórico por decreto Nº 862,
      la iglesia de Trancas. A 70 Km. de Tucumán y a 10 Km. con Salta. La primer
      iglesia fue construíada en 1761. En 1826 un terremoto la destruyó. En 1827
      se construyó nuevamente. En el muro sur de la capilla fue fusilado el
      gobernador de Tucumán, general Bernabé Aráoz y sepultado en su interior.
    </p>
    <p>
      26 de enero de 1833:&nbsp;Nació en Salta Pablo Policarpio Romero, fue
      profesor de latín y escribió una gramática latina, ocupó cargos
      importantes como Perito Contador y Calígrafo de los Tribunales de Justicia
      de Salta, edecán del coronel Francisco Centeno, diputado provincial. Se
      casó con Delfina Juárez Arce y de esa unión nació un niño llamado Gregorio
      Romero, quien llegó a ser Obispo de Salta.
    </p>
    <p>
      27 de enero de 1833:&nbsp;Murió en Salta Calisto Ruiz de Gauna, quien
      trabajando para la corona de España, logró formar una sólida fortuna y ser
      uno de los hombres protagonistas en el Cabildo de Salta al producirse el
      grito de mayo. En 1800 fue nombrado alguacil mayor, luego regidor, cargo
      al que luego le agregó el de comandante de milicias de la intendencia. Por
      este motivo estaba presente cuando el 19 de junio de 1810, el Cabildo de
      Salta resolvía adherirse a la Nueva Junta de gobierno surgida en Buenos
      Aires el 25 de mayo de ese mismo año. El gobernador de Salta, don Nicolás
      Severo de Isasmendi, lo hizo apresar junto a los demás cabildantes y
      fueron alojados en los altos del mismo cabildo. A la noche se escapó y
      cabalgó 352 leguas, para llegar a Buenos Aires, en sólo 8 días. Realizó el
      viaje de retorno con el coronel doctor Feliciano Chiclana quien asumiría
      el cargo de gobernador. Arribaron a la ciudad de Salta el 23 de agosto del
      mismo año (1810). Transcurrió su infancia en Cerrillos, su juventud en
      Sumalao y falleció en Las Costas, en su estancia “El Carmen de Peñalba”.
    </p>
    <p>
      27 de enero de 1930: Nació en Salta, Holver Martínez Borelli. Poeta de
      elevado canto. Publicó en 1968 el libro “Víspera del mar” y en Venezuela
      se publicó un segundo poemario prolongado por Santiago Sylvester, ocho
      años después de su muerte, que lleva por título “Los lugares comunes”. En
      1992, la Comisión Bicameral Examinadora de Obras de Autores Salteños,
      publicó el contenido en los dos poemarios en un solo tomo.
    </p>
    <p>
      27 de enero de 1956:&nbsp;Nació en La Casualidad el poeta Raúl Eduardo
      Rojas, quien publicó entre otros títulos “Decanto”, con la edición de la
      Fundación del Banco del Noroeste. Hace muy poco tiempo presentó su libro
      de poemas titulado Me caigo y me levanto, con ilustraciones del artista
      plástico Antonio Yutronich.
    </p>
    <p>
      27 de enero de 1997:&nbsp;Falleció a los 53 años, el música salteño Oscar
      Hugo Cuellar, conocido popularmente como el Cara I’ Mula. Se destacó
      también como futbolista. Horacio Guarany le hizo un tema en su honor, que
      lo interpretó el autor y Las Voces de Orán.
    </p>
    <p>
      28 de enero de 1782:&nbsp;Se dió la Real Ordenanza de Intendentes, en El
      Pardo, por la que se estipuló que los intendentes tuvieran a su cargo los
      cuatro ramos o causas: Justicia, Policía, Hacienda y Guerra, dándole para
      ello todas las facultades necesarias. Se dictó la Ordenanza de
      Intendentes, que dividió al Virreinato del Río de la Plata en ocho
      gobernaciones intendencias y cuatro gobiernos militares. Salta del Tucumán
      estaba constituída por Jujuy, Salta, Tucumán, Santiago del Estero y
      Catamarca, con capital en Salta.
    </p>
    <p>
      28 de enero de 1851:&nbsp;Nació en Salta, José Arturo León Dávalos
      Isasmendi. Se doctoró en Jurisprudencia. Compartió con Miguel Cané, Rafael
      Obligado y otros escritores. En 1880 fue electo Diputado Nacional. Fiscal
      General de la Provincia. Presidente de la Corte de Justicia. Presidente
      del Consejo de Educación, ministro de Gobierno, diputado nacional por
      segunda vez. Al crearse la Gobernación de los Andes, fue designado
      secretario general de la misma. Senador Provincial, ocupó la Presidencia
      del Cuerpo. Docente en el Colegio Nacional de Salta. Estaba casado con
      Isabel Patrón Costas. De ese hogar nació Juan Carlos Dávalos. Cuando
      ejercía la Presidencia del Senado Provincial de Salta, murió el 4 de
      setiembre de 1900.
    </p>
    <p>
      28 de enero de 1874:&nbsp;Nació en Salta, Nolasco F. Cornejo. En 1899, en
      Buenos Aires, se recibió de Ingeniero Civil. Trabajó para el Ferrocarril.
      Fue Subdirector de la Construcción del Ferrocarril Ledesma-Embarcación.
      Desarrolló el proyecto de construcción de los Talleres del Tafí Viejo.
      Ejerció la docencia en el Colegio Nacional de Salta y en la Escuela
      Normal. Murió el 19 de junio de 1944.
    </p>
    <p>
      29 de enero de 1832:&nbsp;El general Pablo Latorre, perteneciente al
      ejército federal mandado por el general Juan Facundo Quiroga, fue
      derrotado por las tropas salteñas mandadas por el coronel Napoleón Güemes,
      en Las Piedras, provincia de Salta.
    </p>
    <p>
      29 de enero de 1875:&nbsp;Fue sancionada la Reforma de la Constitución
      Salteña, por lo tanto feneció el Consejo de Instrucción Pública y se creó
      el departamento de Instrucción Pública, dependiente del Ministerio de
      Gobierno.
    </p>
    <p>
      29 de enero de 1918:&nbsp;Nació en Salta, Arturo Dávalos. Poeta del
      cancionero popular. Es autor de un bello soneto dedicado a Dios, que lo
      titulo simplemente soneto. Hijo de Juan Carlos Dávalos y esposo de la
      poetisa Sara San Martín.
    </p>
    <p>
      29 de enero de 1976:&nbsp;Se creó la Sociedad de Bomberos Voluntarios de
      Salta “Martín Miguel de Güemes”.
    </p>
    <p>
      <br />
      30 de enero de 1812:&nbsp;Entre salvas de artillería y desfiles militares,
      fue jurada en Salta, la Constitución Española.
    </p>
    <p>
      30 de enero de 1933:&nbsp;Nació en Córdoba la poetisa Ana Luisa Schneider,
      quien desde hace mucho tiempo está radicada en Salta. Publicó “Gavillas al
      viento”, libro de poemas, entre otras obras.
    </p>
    <p>
      31 de enero de 1884:&nbsp;Nació Avelino Aráoz en Salta. Fue consejero del
      Banco Provincial de Salta, diputado, intendente de Rosario de Lerma y de
      la ciudad de Salta. Fue gobernador de Salta entre el 1° de mayo de 1932
      hasta el 1° de mayo de 1936. En 1937 enviado Extraordinario y Ministro
      Plenipotenciario de nuestro país ante el gobierno de Bolivia. Murió en
      Buenos Aires el 11 de agosto de 1959.
    </p>
    <p>
      31 de enero de 1928:&nbsp;Nació en Salta, Julio Alberto Sanmillán. Médico
      especializado en diabetes. Miembro de la Asociación Médica Argentina,
      Presidente de la Comisión Directiva de la Federación Argentina de
      Entidades de Lucha contra la Diabetes. incansable profesional trabajando
      por la salud pública.
    </p>
    <p>
      31 de enero de 1944:&nbsp;Se dispuso la creación de la Dirección General
      de Hidráulica de Salta, que tenía la función de realizar los estudios y
      proyectos, ejecución y fiscalización de obras relacionadas con el riego,
      aguas corrientes, desagües, defensa de las poblaciones y demás cuestiones
      concernientes a la materia hidroeléctrica.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
